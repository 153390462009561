.greenTable {
    background-color: #fff;
    padding-bottom: 20px;

    > table {
        width: 80%;
        margin: 0 auto;
        text-align: left;
        box-sizing: border-box;
        border-collapse: collapse;
        background-color: #fff;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        -webkit-print-color-adjust: exact;
        filter: opacity(1);

        > thead > tr {
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2), 0, 0, 0;
        }

        > thead > tr > th,
        > tbody > tr > td {
            padding: 15px 40px;
            border-bottom: 1px solid $grey;

            &.lastColumn {
                text-align: end;
                border-left: 2px solid $grey;
            }
        }

        th {
            color: $dark-grey;
        }
    }
}
