.signin-btn {
    position: relative;
    width: 185px;
    height: 40px;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    border: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: black;

    &:hover {
        color: black;
        background-color: rgb(235, 235, 235);
    }

    &:active,
    &:visited {
        color: black;
    }

    .btn-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 10px;
        display: flex;
        align-items: center;
    }

    svg {
        width: 18px;
        height: 18px;
        margin-right: 20px;
    }
    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
