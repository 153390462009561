.dashboard .zoomable {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
        position: relative;
        width: 48%;
        min-height: 200px;
        margin-top: 2%;

        cursor: pointer;
        transition: transform 0.5s;

        > div.title,
        > div.background,
        > div.background > div.dimmer {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            &.background {
                padding: 1em;
                background-origin: content-box;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                filter: grayscale(80%);
                > .dimmer {
                    background-color: rgba(0, 0, 0, 0.6);
                    transition: background-color 0.2s;
                }
            }
            &.title {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2em;
                color: white;
            }
        }
    }
    &.active > div {
        transform: scale(0.9);
        &:hover {
            box-shadow: 2px 2px 5px 5px #ccc;
            transform: scale(1.1);
            > div.title {
                color: transparent;
            }
            > div.background {
                filter: none;
                > div.dimmer {
                    background-color: rgba(0, 0, 0, 0);
                }
            }
        }
    }
}
