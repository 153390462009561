.timesheet {
    > .heading {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-flow: wrap;
        background-color: $primary-color;
        color: #fff;
        > .top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin: 30px;
            > .logo {
                height: 30px;
            }
        }
        > .title {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: bold;
        }
        > .period {
            width: 100%;
            text-align: center;
        }
        > .container {
            width: 80%;
            display: flex;
            justify-content: space-between;
            background-color: $light-grey;
            border-radius: 40px 40px 0 0;
            color: #000;
            padding: 30px 40px;
            margin-top: 30px;
            border-bottom: 3px solid $grey;
            > .info {
                > .company {
                    font-size: 20px;
                    font-weight: bold;
                }
                > ul {
                    display: flex;
                    padding: 0;
                    color: $dark-grey;
                    > li {
                        margin-right: 30px;
                        &:first-child {
                            list-style: none;
                        }
                        > .data {
                            display: flex;
                            align-items: baseline;
                            font-weight: bold;
                            > .amount {
                                color: #000;
                                font-size: 20px;
                            }
                            > .unit {
                                margin-left: 5px;
                                color: $dark-grey;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
            > .hourContainer {
                > .totalHours {
                    color: $dark-grey;
                    font-weight: bold;
                }
                > .hours {
                    font-weight: bold;
                    font-size: 30px;
                }
            }
        }
    }
}
