.signing {
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    font-size: 16px;
    padding: 30px 40px;
    p {
        text-align: left;
    }
    .signing-intro {
        margin-top: 0;
    }
    .signing-title {
        margin: 0;
        font-size: 14px;
        line-height: 1.6;
        font-weight: bold;
    }

    .signing-signature {
        width: 250px;
        height: 50px;
        border-bottom: 1px solid #aaa;
    }
}
