h1 {
    font-size: 2rem;
    margin: 25px 0;

    &:first-child {
        margin-top: 0;
    }
}

h2 {
    font-size: 1.5rem;
}

h1.with-sub {
    margin-bottom: 0;
    padding-bottom: 0;

    & + h3 {
        margin-top: 0;
        margin-bottom: 24px;
    }
}

p {
    margin: 20px 0;

    &:last-child {
        margin-top: 0;
    }
}
