body {
    background-color: white;
    color: $pt-text-color;
    font-family: $font;
    font-size: 13px;
    margin: 0;
    padding-top: $pt-navbar-height;
    line-height: $pt-line-height;

    @media print {
        padding: 0;
    }

    &.login {
        background: #30404d;
        color: #fff;
        padding-top: 0;
    }

    *,
    *::before,
    *::after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
    color: inherit;
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

.hide-print {
    @media print {
        display: none;
    }
}

.hide-screen {
    @media screen {
        display: none;
    }
}

@page {
    margin: 20mm 0 20mm 0;
}

@page:first {
    margin: 0 0 20mm 0;
}