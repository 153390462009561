$loader-size: 5em;
$loader-width: 0.5em;

.loader,
.loader:after {
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
}

.loader {
    margin: -($loader-size / 2) 0 0 - ($loader-size / 2);
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: $loader-width solid rgba(83, 82, 83, 0.2);
    border-right: $loader-width solid rgba(83, 82, 83, 0.2);
    border-bottom: $loader-width solid rgba(83, 82, 83, 0.2);
    border-left: $loader-width solid #535253;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translatranslateZ(0);
    -webkit-animation: load 1.1s infinite linear;
    animation: load 1.1s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
}

@-webkit-keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
